import { graphql } from "gatsby"
import React, { useEffect, useRef } from 'react';
import { getCursorFromDocumentIndex } from '@prismicio/gatsby-source-prismic-graphql';
import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import Items from "../components/items"
import styles from "../styles/modules/pagination.module.scss"
import { useTranslation } from 'react-i18next'

export const query = graphql`
  query allItemQuery($lang: String!, $first: Int = 20, $last: Int, $after: String, $before: String) {
    prismic {
      allItems(
        sortBy: meta_firstPublicationDate_DESC
        lang: $lang
        first: $first
        last: $last
        after: $after
        before: $before
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        edges {
          node {
            price
            title
            featured_image
            featured_imageSharp {
              childImageSharp {
                fluid(maxWidth: 1600, quality: 70) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            _meta {
              lang
              uid
            }
          }
        }
      }
    }
  }
`

const IndexPage = props => {
  const limit = 20;
  const didMountRef = useRef(false);
  const [page, setPage] = React.useState(-1);
  const [data, setData] = React.useState(props.data.prismic);

  const onPreviousClick = () => setPage(page - limit);
  const onNextClick = () => setPage(page + limit);
  const { t } = useTranslation('pagination')

  useEffect(() => {
    if (!didMountRef.current) {
      didMountRef.current = true;
      return;
    }

    props.prismic
      .load({ variables: { after: getCursorFromDocumentIndex(page) } })
      .then(res => setData(res.data));
  }, [page]);

  return (
    <Layout>
      <SEO title="Home" />
      <div>
        <Hero />
        <Items items={data.allItems.edges} />
        <div className={styles.pagination}>
          <button class="btn" disabled={page <= 0} onClick={onPreviousClick}>
            {t('previous')}
          </button>
          <button class="btn" disabled={!data.allItems.pageInfo.hasNextPage} onClick={onNextClick}>
            {t('next')}
          </button>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
