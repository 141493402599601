import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { useTranslation } from 'react-i18next'
import styles from "../styles/modules/hero.module.scss"

const Hero = () => {
  const { t } = useTranslation('hero')
  // const data = useStaticQuery(graphql`

  return (
    <StaticQuery
      query={graphql`
        query {
          placeholderImage: file(relativePath: { eq: "loom.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 1500) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      `}
      render={data => {
        return (
          <div className={styles.hero}>
            <Img className={styles.image}
              fluid={data.placeholderImage.childImageSharp.fluid}
              alt={t('title')}
            />
            <div className={styles.content}>
              <h1>{t('title')}</h1>
              <p>{t('subtitle')}</p>
            </div>
          </div>
        )
      }}
    />
  )
}

export default Hero
