import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from 'react-i18next'
import { RichText } from "prismic-reactjs"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import styles from "../styles/modules/items.module.scss"

const Items = ({ items }) => {
  const { i18n } = useTranslation('items')
  if (!items.length) return null

  return (
    <section className={styles.page}>
      <ul className={styles.items}>
        {items
          .filter(edge => edge.node._meta.lang.indexOf(i18n.language) > -1)
          .map(edge => {
            const item = edge.node

            return (
              <li className={styles.item} key={`item-${item._meta.uid}`}>
                <AniLink cover bg="#EEE" to={`${item._meta.lang !== 'en-ca' ? `/${item._meta.lang}` : ''}/pieces/${item._meta.uid}`}>
                  <div className={styles.item__image}>
                    <img className={styles.image}
                      src={item.featured_image.url + '&w=600&q=70'}
                      height={item.featured_image.dimensions.height}
                      width={item.featured_image.dimensions.width}
                      loading="lazy"
                      alt={RichText.asText(item.title)}
                    />
                  </div>
                  <div className={styles.item__info}>
                    <h2 className={styles.item__title}>{RichText.asText(item.title)}</h2>
                    <p className={styles.price}>{item.price.toLocaleString(item._meta.lang, { style: 'currency', currency: 'CAD', currencyDisplay: 'code' }).replace('.00', '').replace(',00', '')}</p>
                  </div>
                </AniLink>
              </li>
            )
          })
        }
      </ul>
    </section>
  )
}

Items.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
}


export default Items
